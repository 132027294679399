import * as React from 'react';
import Layout from '../components/layout';
import Helmet from 'react-helmet';

import {LogoSpinner} from '../components/logo-spinner';

export const NotFoundPage = () => (
    <Layout>
        <Helmet>
            <title>Page Not Foud</title>
        </Helmet>
        <div style={{textAlign: 'center', minHeight: '85.2vh'}}>
            <LogoSpinner />
            <h1>PAGE NOT FOUND</h1>
            <p>OH NO! Looks like your lost.</p>
        </div>
    </Layout>
);

export default NotFoundPage;
