import * as React from 'react';

import './logo-spinner.scss';

export const LogoSpinner: React.FC = () => {
    return (
        <>
            <svg width="250px" height="250px">
                <defs>
                    <linearGradient id="grad">
                        <stop offset="0" stopColor="#FFF11E" />
                        <stop offset="1" stopColor="#D30F0F" />
                    </linearGradient>
                    <linearGradient id="grad2">
                        <stop offset="0" stopColor="#D30F0F" />
                        <stop offset="1" stopColor="#D87319" />
                    </linearGradient>
                </defs>
                <svg
                    id="triangle"
                    width="250px"
                    height="250px"
                    viewBox="-3 -4 39 39"
                >
                    <polygon
                        fill="rgba(0, 0, 0, 0.04)"
                        stroke="url(#grad)"
                        strokeWidth="3"
                        strokeLinecap="round"
                        points="16,0 32,32 0,32"
                    ></polygon>
                </svg>
                <svg id="text" width="250" height="250" x="98" y="125">
                    <g id="e" data-name="E">
                        <g id="e_2" data-name="E_2">
                            <path
                                xmlns="http://www.w3.org/2000/svg"
                                className="cls-1"
                                d="M 49.05 55.34 v 4.25 a 3.76 3.76 0 0 1 -3.76 3.76 H 13.57 A 13.57 13.57 0 0 1 0 49.78 V 13.57 A 13.56 13.56 0 0 1 13.57 0 H 44.11 a 3.76 3.76 0 0 1 3.76 3.76 V 8 a 3.76 3.76 0 0 1 -3.76 3.76 H 19.73 a 5.16 5.16 0 0 0 -5.16 5.16 v 3.43 a 5.16 5.16 0 0 0 5.16 5.16 H 40.22 A 3.76 3.76 0 0 1 44 29.28 v 3.88 a 3.76 3.76 0 0 1 -3.76 3.76 H 19.73 a 5.16 5.16 0 0 0 -5.16 5.17 v 4.33 a 5.16 5.16 0 0 0 5.16 5.16 H 45.29 A 3.76 3.76 0 0 1 49.05 55.34 Z"
                            />
                        </g>
                    </g>
                </svg>
            </svg>
        </>
    );
};
